import React from "react";
import { Route, Switch } from "react-router-dom";
import ConsultaFinanciera from "../pages/constulta-financiera";
import Home from "../pages/home";

const Routes = () => {
  return (
    <Switch>
      <Route exact={true} path="/" component={Home} />
      <Route exact={true} path="/consulta-financiera" component={ConsultaFinanciera} />
      <Route path="/:modal" component={Home} />
      {/* <Route exact={true} path="/wps/portal/proteccion/web/nueva-zona-pensional" component={Home} />
            <Route path="/wps/portal/proteccion/web/nueva-zona-pensional/:modal" component={Home} /> */}
    </Switch>
  );
};

export default Routes;
