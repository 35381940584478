import React from "react";
import "./banner.scss";

function Banner({ className, src }) {
  return (
    <img className={`banner ${className || ""}`} src={src} alt=''/>
  )
}

Banner.propTypes = {};

export default Banner;
