import React from 'react';
import Routes from "./util/routes"

function App() {
  return (
    <div className="App">
        <Routes />
    </div>
  );
}

export default App;
