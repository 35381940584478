import React, { useEffect, useRef, useState } from "react";
import "./home.scss";
import Paragraph from "../components/atoms/paragraph/paragraph";
import Banner from "../components/molecules/banner/banner";
import Body from "../components/organisms/body/body";
import image from "../assets/images/Banner_AC2.jpg";
import Input from "../components/atoms/input/input";
import CanvasDraw from "react-canvas-draw";
import axios from "axios";
import { resolver } from "../util/objectUtilities";
import { createHash } from "crypto";
import ReCAPTCHA from "react-google-recaptcha";

function ConsultaFinanciera() {
  const saveableCanvas = useRef();
  const textBody = useRef();
  const recaptchaRef = useRef();
  const [asesores, setAsesores] = useState();
  const [proyectos, setProyectos] = useState();
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [formValues, setFormValues] = useState({
    nombre: "",
    apellido: "",
    tipoDoc: "",
    numeroDoc: "",
    email: "",
    celular: "",
    proyecto: "",
  });

  function KeyPress(event) {
    if (event.ctrlKey && event.key === "z") {
      saveableCanvas.current.undo();
    }
  }

  if (typeof document !== "undefined") {
    document.addEventListener("keydown", KeyPress);
  }

  useEffect(() => {
    try {
      const tmpAsesores = sessionStorage.getItem("asesores");
      const tmpProyectos = sessionStorage.getItem("proyectos");
      if (!tmpAsesores && !sessionStorage.getItem("calling1")) {
        sessionStorage.setItem("calling1", "true");
        axios("/hubspot-owners.json")
          .then((res) => {
            const ases = resolver(res, "data");
            if (ases) {
              sessionStorage.setItem("asesores", JSON.stringify(ases));
              setAsesores(() => ases);
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            sessionStorage.removeItem("calling1");
          });
      } else {
        setAsesores(() => JSON.parse(tmpAsesores));
      }

      if (!tmpProyectos && !sessionStorage.getItem("calling2")) {
        sessionStorage.setItem("calling2", "true");
        axios("/projects.json")
          .then((res) => {
            const proye = resolver(res, "data");
            if (proye) {
              sessionStorage.setItem("proyectos", JSON.stringify(proye));
              setProyectos(() => proye);
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            sessionStorage.removeItem("calling2");
          });
      } else {
        setProyectos(() => JSON.parse(tmpProyectos));
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  function cleanForm() {
    setFormValues(() => {
      return Object.keys(formValues).reduce((result, item) => {
        return { ...result, [item]: "" };
      }, {});
    });
  }

  function handleInput(name, value) {
    name &&
      setFormValues((s) => {
        return {
          ...s,
          [name]: value,
        };
      });
  }

  async function handleSubmit() {
    try {
      const token = await recaptchaRef.current.executeAsync();

      if (token) {
        setError(false);
        setMessage("");
        const tmpSign = saveableCanvas.current.canvas.drawing.toDataURL(
          "image/png"
        );

        const hash = createHash("SHA256");
        const digest = hash.update(tmpSign).digest("hex");

        if (
          digest ===
          "db098c20b694ddb05240926978308c3a3a30b5cd539101ee0686aa5fcac6605e"
        ) {
          setError(true);
          setMessage("la firma está vacía");
          return false;
        }

        const [proyectoId, nombreProyecto] = formValues.proyecto.split("||");

        axios({
          url:
            "https://6ps4wb8wjb.execute-api.us-east-1.amazonaws.com/prod/ayc/api/v1/consultaFinancieraExpresa",
          method: "POST",
          data: {
            parrafo: textBody.current.innerHTML,
            nombre: formValues.nombre,
            apellido: formValues.apellido,
            tipoDoc: formValues.tipoDoc,
            numeroDoc: formValues.numeroDoc,
            email: formValues.email,
            celular: formValues.celular,
            proyecto: proyectoId,
            asesor: formValues.asesor,
            nombreProyecto: nombreProyecto,
            firma: tmpSign,
            recaptcha: token,
          },
        })
          .then((res) => {
            if (res && res.status === 200) {
              setMessage(() => resolver(res, "data.input", "Enviado ok"));
            }
          })
          .catch((e) => {
            if (e) {
              if (e.isAxiosError) {
                let res = e.response;
                if (res) {
                  setError(true);
                  setMessage(() => resolver(res, "data.input", e.message));
                }
              }
              console.error(e);
            }
          });
      }
    } catch (e) {
      setError(true);
      console.error(e);
    }
  }

  return (
    <Body className="home">
      <Banner src={image} />
      <div ref={textBody}>
        <h2>AUTORIZACIÓN PARA CONSULTA DE SITUACIÓN FINANCIERA.</h2>
        <Paragraph>
          Autorizó de manera expresa y voluntaria a ARQUITECTURA Y CONCRETO o a
          quién represente sus derechos y ostente en el futuro la calidad de
          acreedor, a reportar, procesar y solicitar la información que se
          refiere a mi comportamiento crediticio, financiero y comercial a las
          Centrales de Riesgo que administra la Asociación Bancaria y de
          Entidades Financieras de Colombia o a quien represente sus derechos.
        </Paragraph>
        <Paragraph>
          Lo anterior implica que el cumplimiento o incumplimiento de mis
          obligaciones se reflejará en las mencionadas bases de datos, en donde
          se consignan de manera completa, todos los datos referentes a mi
          actual y pasado comportamiento frente al sector financiero y, en
          general, frente al cumplimiento de mis obligaciones. Igualmente,
          manifiesto que conozco y acepto que la consecuencia de esta
          autorización será la consulta e inclusión de mis datos financieros en
          Centrales de Riesgo o demás entidades que manejan este tipo de
          información, por tanto, las entidades del sector financiero afiliadas
          a dichas centrales conocerán el comportamiento presente y pasado
          relacionado con el cumplimiento o incumplimiento de mis obligaciones
          financieras.
        </Paragraph>
      </div>
      <button
        onClick={(e) => {
          e.preventDefault();
          cleanForm();
        }}
        className="btn"
      >
        Limpiar formulario
      </button>
      <form
        id="mainForm"
        className="forms"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="container-form">
          <h1>Información del Cliente</h1>
          <div className="table">
            <Input
              name="nombre"
              required={true}
              value={formValues.nombre}
              setValue={handleInput}
              label="Nombres"
              type="text"
            ></Input>
            <Input
              name="apellido"
              required={true}
              value={formValues.apellido}
              setValue={handleInput}
              label="Apellidos"
              type="text"
            ></Input>
            <Input
              name="tipoDoc"
              required={true}
              value={formValues.tipoDoc}
              setValue={handleInput}
              label="Tipo Documento"
              type="select"
            >
              <option value="">Seleccione un tipo</option>
              <option value="CC">CC</option>
              <option value="NIT">NIT</option>
              <option value="CE">CE</option>
              <option value="PS">PS</option>
            </Input>
            <Input
              name="numeroDoc"
              required={true}
              value={formValues.numeroDoc}
              setValue={handleInput}
              label="Número Documento"
              type="number"
            ></Input>
            <Input
              name="email"
              required={true}
              value={formValues.email}
              setValue={handleInput}
              label="Correo Electrónico"
              type="text"
            ></Input>
            <Input
              name="celular"
              required={true}
              value={formValues.celular}
              setValue={handleInput}
              label="Celular"
              type="number"
            ></Input>
          </div>
        </div>
        <div className="container-form">
          <h1>Información del Proyecto</h1>
          <div className="table">
            <Input
              name="proyecto"
              required={true}
              value={formValues.proyecto}
              setValue={handleInput}
              label="Proyecto"
              type="select"
            >
              <option value="">Seleccione un Proyecto</option>
              {Array.isArray(proyectos) &&
                proyectos.map((proye) => (
                  <option
                    key={proye.value}
                    value={`${proye.value}||${proye.label}`}
                  >
                    {proye.label}
                  </option>
                ))}
            </Input>
            <Input
              name="asesor"
              required={true}
              value={formValues.asesor}
              setValue={handleInput}
              label="Asesor"
              type="select"
            >
              <option value="">Seleccione un Asesor</option>
              {Array.isArray(asesores) &&
                asesores.map((asesor) => (
                  <option key={asesor.id} value={asesor.id}>
                    {asesor.fullName}
                  </option>
                ))}
            </Input>
          </div>
        </div>
        <div className="container-form order-last-mobile">
          <CanvasDraw
            ref={(canvasDraw) => (saveableCanvas.current = canvasDraw)}
            lazyRadius={0}
            className="sign"
            brushColor="000000"
            brushRadius={3}
            canvasHeight={300}
            canvasWidth={800}
          ></CanvasDraw>
          <div className="sign-controls">
            <button
              type="button"
              className="btn"
              onClick={() => {
                saveableCanvas.current.undo();
              }}
            >
              Deshacer
            </button>
          </div>
        </div>
        <div className="submit-container">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
            size="invisible"
          />
          <button className="btn" type="submit">
            Enviar
          </button>
        </div>
      </form>
      {message && (
        <span className={`mssg${error ? "-error" : ""}`}>{message}</span>
      )}
    </Body>
  );
}

export default ConsultaFinanciera;
