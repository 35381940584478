
import React, {useEffect, useRef, useState} from "react";
import "./home.scss";
import Paragraph from "../components/atoms/paragraph/paragraph";
import Banner from "../components/molecules/banner/banner";
import Body from "../components/organisms/body/body";
import image from "../assets/images/Banner-Habeas.jpg";
import Input from "../components/atoms/input/input";
import CanvasDraw from "react-canvas-draw";
import axios from "axios";
import {resolver} from "../util/objectUtilities";
import {createHash} from "crypto";
import ReCAPTCHA from "react-google-recaptcha";


function Home() {
    const saveableCanvas = useRef();
    const textBody = useRef();
    const [asesores, setAsesores] = useState();
    const [proyectos, setProyectos] = useState();
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [formValues, setFormValues] = useState({
        tipoUsuario: "",
        nombre: "",
        apellido: "",
        tipoDoc: "",
        numeroDoc: "",
        email: "",
        celular: "",
        proyecto: "",
        asesor: "",
        pregunta1: "",
        pregunta2: "",
        pregunta3: "",
        pregunta4: "",
        pregunta5: "",
        temperatura: "",
    });

    const recaptchaRef = useRef();

    // const preguntas = {
    //   pregunta1:
    //     "1. ¿Tiene fiebre o la ha tenido en los últimos 14 días? (temperatura igual o mayor a 38°C)",
    //   pregunta2:
    //     "2. ¿Tiene o ha tenido en los últimos 14 días diarrea u otras molestias digestivas? ",
    //   pregunta3:
    //     "3. ¿Tiene o ha tenido sensación de mucho cansancio o malestar en los últimos 14 días? ",
    //   pregunta4:
    //     "4. ¿Ha notado una pérdida del sentido del gusto o del olfato en los últimos 14 días? ",
    //   pregunta5:
    //     "5. ¿Ha estado en contacto o conviviendo con alguna persona sospechosa o confirmada por COVID-19? ",
    // };

    function KeyPress(event) {
        if (event.ctrlKey && event.key === "z") {
            saveableCanvas.current.undo();
        }
    }

    if (typeof document !== "undefined") {
        document.addEventListener("keydown", KeyPress);
    }

    function handleInput(name, value) {
        name &&
        setFormValues((s) => {
            return {
                ...s,
                [name]: value,
            };
        });
    }

    useEffect(() => {
        try {
            //const tmpAsesores = sessionStorage.getItem("asesores");
            const tmpProyectos = sessionStorage.getItem("proyectos");
            //if (!tmpAsesores && !sessionStorage.getItem("calling1")) {
            sessionStorage.setItem("calling1", "true");
            axios("/hubspot-owners-local.json")
                .then((res) => {
                    const ases = resolver(res, "data");
                    if (ases) {
                        sessionStorage.setItem("asesores", JSON.stringify(ases));
                        setAsesores(() => ases);
                    }
                })
                .catch((e) => {
                    console.error(e);
                })
                .finally(() => {
                    sessionStorage.removeItem("calling1");
                });
            //} else {
            //  setAsesores(() => JSON.parse(tmpAsesores));
            //}

            if (!tmpProyectos && !sessionStorage.getItem("calling2")) {
                sessionStorage.setItem("calling2", "true");
                axios("/projects_local.json")
                    .then((res) => {
                        const proye = resolver(res, "data");
                        if (proye) {
                            sessionStorage.setItem("proyectos", JSON.stringify(proye));
                            setProyectos(() => proye);
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                    })
                    .finally(() => {
                        sessionStorage.removeItem("calling2");
                    });
            } else {
                setProyectos(() => JSON.parse(tmpProyectos));
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    async function handleSubmit() {
        try {
            const token = await recaptchaRef.current.executeAsync();

            if (token) {
                setError(false);
                setMessage("");
                const tmpSign = saveableCanvas.current.canvas.drawing.toDataURL(
                    "image/png"
                );

                const hash = createHash("SHA256");
                const digest = hash.update(tmpSign).digest("hex");

                if (
                    digest ===
                    "db098c20b694ddb05240926978308c3a3a30b5cd539101ee0686aa5fcac6605e"
                ) {
                    setError(true);
                    setMessage("la firma está vacía");
                    return false;
                }

                const [proyectoId, nombreProyecto] = formValues.proyecto.split("||");

                // const descripcion = Object.keys(preguntas)
                //   .map((pregunta) => `${preguntas[pregunta]}: ${formValues[pregunta]}`)
                //   .join("\n");
                console.log(textBody.current.innerHTML);
                axios({
                    url:
                        "https://6ps4wb8wjb.execute-api.us-east-1.amazonaws.com/prod/ayc/api/v1/habeasData",
                    method: "POST",
                    data: {
                        parrafo: textBody.current.innerHTML,
                        nombre: formValues.nombre,
                        apellido: formValues.apellido,
                        tipoDoc: formValues.tipoDoc,
                        numeroDoc: formValues.numeroDoc,
                        tipoUsuario: formValues.tipoUsuario,
                        email: formValues.email,
                        celular: formValues.celular,
                        proyecto: proyectoId,
                        asesor: formValues.asesor,
                        nombreProyecto: nombreProyecto,
                        pregunta1: formValues.pregunta1,
                        pregunta2: formValues.pregunta2,
                        pregunta3: formValues.pregunta3,
                        pregunta4: formValues.pregunta4,
                        pregunta5: formValues.pregunta5,
                        temperatura: formValues.temperatura,
                        //descripcion: descripcion,
                        firma: tmpSign,
                        recaptcha: token,
                    },
                })
                    .then((res) => {
                        if (res && res.status === 200) {
                            setMessage(() => resolver(res, "data.input", "Enviado ok"));
                            cleanForm();
                        }
                    })
                    .catch((e) => {
                        if (e) {
                            if (e.isAxiosError) {
                                let res = e.response;
                                if (res) {
                                    setError(true);
                                    setMessage(() => resolver(res, "data.input", e.message));
                                }
                            }
                            console.error(e);
                        }
                    });
            }
        } catch (e) {
            setError(true);
            console.error(e);
        }
    }

    function cleanForm() {
        setFormValues(() => {
            return Object.keys(formValues).reduce((result, item) => {
                return {...result, [item]: ""};
            }, {});
        });
    }

    return (
        <Body className="home">

            <Banner src={image}/>

            <div className="container-text">
            <div className="text" ref={textBody}>

                <h2>AVISO DE PRIVACIDAD ARQUITECTURA Y CONCRETO S.A.S. </h2>
                <Paragraph>
                    Arquitectura y Concreto S.A.S, en cumplimiento de la Ley 1581 de 2012 y demás normas concordantes,
                    es responsable del tratamiento de sus datos personales.

                    Los datos personales que Arquitectura y Concreto S.A.S, recolecta serán utilizados para el
                    cumplimiento de su objeto social y conforme su política de tratamiento de datos, los cuales serán
                    tratados para las siguientes finalidades:
                </Paragraph>

                <ul>
                    <li>
                        Realizar invitaciones a eventos y ofrecer nuevos productos o servicios, descuentos, promociones,
                        a través de cualquier medio o canal, de acuerdo con y los avances tecnológicos y el perfil del
                        cliente.
                    </li>
                    <li>
                        Dar a conocer y participar de campañas y/o concursos promovidos por Arquitectura y Concreto
                        relacionados con su objeto social.
                    </li>
                    <li>
                        Solicitar información de sus familias para el desarrollo de las campañas, eventos y/o concursos
                        promovidos por Arquitectura y Concreto.
                    </li>
                    <li>
                        Captura y uso de fotografías y/o videograbación con sus datos para ser publicada en repositorios
                        de la compañía o a quienes esta transfiera o trasmita en los términos de la Ley 1581 de 2022 y
                        su decreto reglamentario, como redes sociales tales como Twitter, Instagram, Youtube, Facebook,
                        Tik Tok, u otras conocidas o por conocer, la página web de Arquitectura y Concreto e inclusive
                        para la publicación en medios impresos y/o publicitarios de gran o pequeño formato.
                    </li>
                    <li>
                        Utilizar los datos personales, imágenes, videos, registros fílmicos de diferentes tipos tomados
                        con su autorización para la realización de campañas promocionales de Arquitectura y Concreto.
                        Estas fotos y/o videograbaciones podrán tratarse en formato o soporte material, en ediciones
                        impresas o en medio electrónico, óptico, magnético, en redes, (Intranet e Internet), mensajes de
                        datos o similares y en general para cualquier medio o soporte conocido o por conocer en el
                        futuro.
                    </li>


                    <li>
                        Realizar todas las gestiones necesarias tendientes a confirmar y actualizar la información del
                        cliente, incluyendo cuando sea necesario, la consulta y reproducción de datos sensibles tales
                        como la huella digital, imagen, voz, entre otros.
                    </li>
                    <li>
                        Consultar en listas restrictivas para el cumplimiento de políticas SAGRILAFT.
                    </li>
                    <li>
                        Efectuar encuestas de satisfacción respecto de los bienes y servicios ofrecidos
                    </li>
                    <li>
                        Realizar encuestas, estudios, confirmación de datos personales, envío de noticias relacionadas
                        con campañas de fidelización, mejora de los servicios, envío de extractos, estados de cuenta o
                        facturas.
                    </li>
                    <li>
                        Consultar datos personales de clientes y/o potenciales clientes, en las diferentes bases de
                        datos de la seguridad social, de forma directa o a través de operadores de información PILA
                        tales como ARUS (ENLACE OPERATIVO), SOI, MI PLANILLA, FEDECAJAS, o quien haga sus veces, entre
                        otros, así como en las diferentes Cajas de Compensación y Sistema de Identificación de
                        Potenciales Beneficiarios de Programas Sociales (SISBEN).
                    </li>
                    <li>
                        Cumplir con las obligaciones que surgen de la relación contractual.
                    </li>
                    <li>
                        Gestionar y contestar peticiones, consultas, quejas, reclamos, respecto de los datos personales
                        de los titulares y/o de cualquier naturaleza (ventas, servicio postventa, servicio al cliente,
                        entre otros).
                    </li>
                    <li>
                        Responder a solicitudes de autoridades judiciales o administrativas competentes, procurando en
                        todo caso divulgar sólo la información pertinente y necesaria para dar respuesta a la respectiva
                        solicitud.
                    </li>
                    <li>
                        Transferir y/o transmitir datos de contacto a la fuerza comercial y/o red de distribución,
                        tele-mercadeo, investigación de mercados y/o cuando se tercerice la comercialización y los
                        trámites de los proyectos inmobiliarios.
                    </li>
                    <li>
                        Las demás finalidades que se determinen en procesos de obtención de Datos Personales para su
                        tratamiento, y en todo caso de acuerdo con la Ley y en el marco del objeto social de
                        Arquitectura y Concreto.
                    </li>

                </ul>
                <Paragraph>
                    En cuanto a los audios y videos de vigilancia instalados en las instalaciones de Arquitectura y
                    Concreto S.A.S. y/o capturadas en las llamadas telefónicas, son utilizados para efectos de
                    protección, control y calidad. La información recolectada se utilizará para fines de seguridad,
                    control interno y calidad de las personas, los bienes e instalaciones. Esta información podrá ser
                    usada como prueba en cualquier proceso, judicial o administrativo y podrá ser suministrada a las
                    autoridades previo requerimiento de estas.
                </Paragraph>

                <Paragraph>
                    Arquitectura y Concreto podrá transferir o transmitir los datos de contacto a las compañías aliadas
                    y/o cualquier persona o sociedad que haga parte del grupo empresarial y/o un vehículo jurídico que
                    constituya, o en el cual se encuentre incluido Arquitectura y Concreto S.A.S. como parte de este,
                    para ofrecer sus productos o servicios. También podrá Transmitir y/o transferir los datos personales
                    con la(s) entidad(es) financiera(s), intermediarios bancarios y/o Bancos, con el fin de que estas
                    puedan hacer proyección y análisis de viabilidad de créditos, ofrecer productos y servicios
                    financieros, y en general, realizar ofertas comerciales y de financiación para la adquisición de
                    unidades inmobiliarias con Arquitectura y Concreto. Podrá igualmente Transmitir datos de contacto a
                    la fuerza comercial y/o red de distribución, tele-mercadeo, investigación de mercados y/o cuando se
                    tercerice la comercialización y los trámites de los proyectos inmobiliarios. Lo anterior teniendo en
                    cuenta el debido control en los términos del decreto reglamentario 1377 de 2013 o la norma que lo
                    adicione, modifique o sustituya y el capítulo VII. Transferencia y trasmisión de datos personales de
                    la presente política.
                </Paragraph>
                <Paragraph>
                    Como titular de información tiene derecho a: Conocer, actualizar y rectificar sus datos personales;
                    solicitar prueba de la autorización otorgada salvo cuando expresamente se exceptúe como requisito
                    para el Tratamiento; ser informado, previa solicitud, respecto del uso que le darán a sus datos
                    personales; consultar sus datos personales y hacer reclamos para salvaguardar su derecho a la
                    protección de sus datos personales; revocar la autorización y/o solicitar la supresión en los casos
                    que sea procedente; acceder en forma gratuita a sus datos personales que hayan sido objeto de
                    Tratamiento.
                </Paragraph>
                <Paragraph>
                    Como titular de información tiene derecho a: Conocer, actualizar y rectificar sus datos personales;
                    solicitar prueba de la autorización otorgada salvo cuando expresamente se exceptúe como requisito
                    para el Tratamiento; ser informado, previa solicitud, respecto del uso que le darán a sus datos
                    personales; consultar sus datos personales y hacer reclamos para salvaguardar su derecho a la
                    protección de sus datos personales; revocar la autorización y/o solicitar la supresión en los casos
                    que sea procedente; acceder en forma gratuita a sus datos personales que hayan sido objeto de
                    Tratamiento.
                </Paragraph>
                <Paragraph>
                    Para ampliar y conocer nuestra política de tratamiento de datos personales y los cambios
                    sustanciales que se produzcan en ella podrá hacerlo consultando aquí <a
                    href="https://arquitecturayconcreto.com/">https://arquitecturayconcreto.com/</a>. Para los titulares
                    que no tengan acceso a medios electrónicos o aquellos a los que no sea posible contactar, se
                    comunicará a través de avisos abiertos en la sede principal de la empresa.
                </Paragraph>
                <Paragraph>Si desea presentar una consulta, reclamo o petición de información relacionada con la
                    protección de datos personales puede realizarlo a través de los siguientes canales:
                </Paragraph>
                <ul>

                    <li>Sitio web:<a href="https://arquitecturayconcreto.com/">www.arquitecturayconcreto.com</a></li>
                    <li>Correo electrónico:<a
                        href="mailto:protecciondedatos@arquitecturayconcreto.com">protecciondedatos@arquitecturayconcreto.com</a>
                    </li>
                    <li>Punto de atención personal: Calle 3 sur Nro. 43ª-52. Oficina 1801. Edificio 43 Avenida -
                        Medellín (Antioquia)
                    </li>
                    <li>Telefónicamente: (604) 3123618</li>
                </ul>
                <Paragraph>
                    Dirigido al área de protección de datos de ARQUITECTURA Y CONCRETO S.A.S.
                </Paragraph>

            </div>
            </div>

            <form
                id="mainForm"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
            >
                <div className="form">
                    <div className="content-components">
                    <div className="content-form">
                    <h1 className="header1">Información del Cliente</h1>
                    <div className="content-input">
                        <Input
                            name="tipoUsuario"
                            required={true}
                            value={formValues.tipoUsuario}
                            setValue={handleInput}
                            label="Tipo Usuario"
                            type="select"
                        >
                            <option value="">Tipo de Usuario </option>
                            <option value="Cliente">Cliente</option>
                        </Input>
                        <Input
                            name="nombre"
                            required={true}
                            value={formValues.nombre}
                            setValue={handleInput}
                            label="Nombres"
                            type="text"
                        ></Input>
                        <Input
                            name="apellido"
                            required={true}
                            value={formValues.apellido}
                            setValue={handleInput}
                            label="Apellidos"
                            type="text"
                        ></Input>
                        <Input
                            name="tipoDoc"
                            required={false}
                            value={formValues.tipoDoc}
                            setValue={handleInput}
                            label="Tipo Documento"
                            type="select"
                        >
                            <option value=""> Tipo Documento </option>
                            <option value="CC">CC</option>
                            <option value="NIT">NIT</option>
                            <option value="CE">CE</option>
                            <option value="PS">PS</option>
                        </Input>
                        <Input
                            name="numeroDoc"
                            required={false}
                            value={formValues.numeroDoc}
                            setValue={handleInput}
                            label="Número Documento"
                            type="number"
                        ></Input>
                        <Input
                            name="email"
                            required={true}
                            value={formValues.email}
                            setValue={handleInput}
                            label="Correo Electrónico"
                            type="text"
                        ></Input>
                        <Input
                            name="celular"
                            required={false}
                            value={formValues.celular}
                            setValue={handleInput}
                            label="Celular"
                            type="number"
                        ></Input>
                    </div>
                </div>
                </div>
                <div className="content-components">

                    <div className="content-form">
                    <h1 className="header1">Información del Proyecto</h1>
                    <div className="content-input">
                        <Input
                            name="proyecto"
                            required={true}
                            value={formValues.proyecto}
                            setValue={handleInput}
                            label="Proyecto"
                            type="select"
                        >
                            <option value="">Proyecto</option>
                            {Array.isArray(proyectos) &&
                                proyectos.map((proye) => (
                                    <option
                                        key={proye.value}
                                        value={`${proye.value}||${proye.label}`}
                                    >
                                        {proye.label}
                                    </option>
                                ))}
                        </Input>
                        <Input
                            name="asesor"
                            required={true}
                            value={formValues.asesor}
                            setValue={handleInput}
                            label="Asesor"
                            type="select"
                        >
                            <option value="">Asesor</option>
                            {Array.isArray(asesores) &&
                                asesores.map((asesor) => (
                                    <option key={asesor.id} value={asesor.id}>
                                        {asesor.fullName}
                                    </option>
                                ))}
                        </Input>
                    </div>
                </div>
                <div className="container-order-last-mobile">
                    <CanvasDraw
                        ref={(canvasDraw) => (saveableCanvas.current = canvasDraw)}
                        lazyRadius={0}
                        brushColor="000000"
                        brushRadius={0}
                        canvasHeight={180}
                        canvasWidth={600}
                        style={{
                            border:'1px solid',
                            borderRadius: '10px'

                        }}
                    ></CanvasDraw>
                        <button
                            type="button"
                            className="btn"
                            onClick={() => {
                                saveableCanvas.current.undo();
                            }}
                        >
                            Deshacer
                        </button>
                </div>


                <div className="submit-container">
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                        size="invisible"/>
                  </div>
                </div>
            </div>
            <div className="container-label">
                <label className="label">
                    <input type="checkbox" required={true}/>

                    Acepto el tratamiento de mis datos personales, <a
                    href=" https://arquitecturayconcreto.com/politicas-de-privacidad/"> las políticas de
                    privacidad </a> y <a href="https://arquitecturayconcreto.com/terminos-y-condiciones/"> los términos
                    y condiciones.</a>
                </label>
                       <button
                       className="btn-enviar"
                       type="submit">
                        Enviar
                     </button>
                </div>

            </form>
            {message && (
                <span className={`mssg${error ? "-error" : ""}`}>{message}</span>
            )}
        </Body>
    );
}

export default Home;
