import React from "react";
import PropTypes from "prop-types";
import "./paragraph.scss";

function Paragraph({ className, children }) {
  return (<p className={className}>{children}</p>)
}

Paragraph.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default Paragraph;