import React from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import "./input.scss";

function Input({
  className,
  name,
  required,
  id,
  label,
  labelClass,
  type,
  children,
  value,
  setValue,
}) {
  id = id || uuid();

  const labelLength = label.length;
  labelClass = labelClass ? `label-${labelClass}` : "";

  if (!labelClass) {
    switch (true) {
      case labelLength < 40:
        labelClass = "label-small";
        break;
      case labelLength >= 40 && labelLength < 80:
        labelClass = "label-medium";
        break;
      case labelLength >= 80:
        labelClass = "label-long";
        break;
      default:
        break;
    }
  }

  function InputByType(type) {
    switch (type) {
      case "textarea":
        return <textarea name={name} id={id} />;
      case "select":
        return (
          <select
            required={required}
            name={name}
            id={id}
            onChange={(e) => {
              e.preventDefault();
              setValue(e.target.name, e.target.value);
            }}
          >
            {children}
          </select>
        );
      default:
        return (
          <input
            required={required}
            name={name}
            id={id}
            placeholder={label}
            type={type}
            value={value}
            onChange={(e) => {
              e.preventDefault();
              setValue(e.target.name, e.target.value);
            }}
          />
        );
    }
  }

  return (
    <div className={`input ${className || ""}`}>    
      <div className="container-input">{InputByType(type)}</div>
    </div>
  );
}

Input.defaultProps = {
  required: false
};

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  labelClass: PropTypes.oneOf(["small", "medium", "long"]),
  type: PropTypes.oneOf([
    "button",
    "checkbox",
    "color",
    "date",
    "datetime-local",
    "email",
    "file",
    "hidden",
    "image",
    "month",
    "number",
    "password",
    "radio",
    "range",
    "reset",
    "search",
    "submit",
    "tel",
    "text",
    "time",
    "url",
    "week",
    "textarea",
    "select",
  ]),
  value: PropTypes.any,
  setValue: PropTypes.func.isRequired
};

export default Input;
