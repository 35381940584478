import React from "react";
import PropTypes from "prop-types";
import "./body.scss";

function Body({className, children}) {
  return (
    <div className={`body ${className || ""}`}>
      {children}
    </div>
  );
}

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default Body;